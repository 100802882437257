<script>
import CalendrierPreview from '@/components/presentation/calendarPreview.vue'

import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'

export default {
  name: 'createCalendarListe',
  data () {
    return {
      calendarName: null,
      calendarColor: '#F44336',
      colors: ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFC107', '#FF9800', '#FF5722', '#795548', '#9E9E9E', '#607D8B'],
      selectedCalendars: null,
      step: 1
    }
  },
  props: {
  },
  components: {
    CalendrierPreview,
    VSwatches
  },
  computed: {
    showCreateCalendar: {
      set (val) { this.$store.commit('Dialogs/setCreateCalendar', val) },
      get () { return this.$store.state.Dialogs.showCreateCalendar }
    }
  },
  mounted () {
  },
  watch: {
  },
  methods: {
    reset () {
      this.calendarName = null
      this.calendarColor = '#F44336'
      this.step = 1
      this.selectedCalendars = null
    },
    createList () {
      this.showCreateCalendar = false
    },
    closeCalendar () {
      this.reset()
      this.showCreateCalendar = false
    }
  }
}
</script>

<template lang='pug'>
v-dialog(v-model='showCreateCalendar', max-width='600', content-class='custom-dialog', persistent, scrollable)

  v-card.light
    v-card-title.dialog-title.secondary--text Ajouter un calendrier
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click='closeCalendar()', @click.native='reset', depressed, fab, x-large, color='secondary')
        font-awesome-icon.f3(:icon="['fas', 'times']")
    div.ph4.mb2
      v-btn(v-if='step !== 1', @click='step = 1', rounded, block, outlined, small)
        | Retour
    v-card-text
      v-window(v-model='step')
        v-window-item(:value='1')
          v-card-text
            div.flex.flex-column
              v-btn(@click='step = 2', x-large, rounded, depressed, color='secondary') Explorer les calendriers public
              v-btn.mt3(@click='step = 3', x-large, rounded, depressed, color='secondary') Créer un calendrier
        v-window-item(:value='2')
          v-card-text
            p Voici les calendrier disponibles
            v-expansion-panels.exercices-panel(accordion)
              template(v-for='(n, index) in 6')
                calendrier-preview(:hardcodded='true', :index='index')

        v-window-item(:value='3')
          v-card-text
            v-text-field(placeholder='Titre du calendrier', solo, outlined, flat, hide-details, v-model='calendarName', clearable, @keyup.enter='createList')
            div.flex.items-center.mt3
              div.f4.ml2 Couleur des événements
              div.flex-grow-1
              v-swatches(v-model='calendarColor', popover-x='left', shapes='circles', :swatches='colors')
              v-divider.mh3(vertical)
              div
                div.i.f7 Exemple:
                .v-event-timed.white--text(:style='{backgroundColor : calendarColor}', style='border-radius: 10px')
                  .v-event-draggable(style='padding: 0 16px 16px 10px; border-radius: 10px;')
                    strong Nom de l'événement
                    br
                    | 15 h 15 - 17 h 15
    v-btn(v-if='step === 3' @click='createList', color='primary', depressed, block, x-large, :disabled='!calendarName && step !== 3')
      span.f4 Créer le calendrier
</template>
<style lang='sass'>

</style>
