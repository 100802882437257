<script>

export default {
  name: 'EventPreview',
  props: {
    selectedEvent: { required: true, type: Object }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    getDate (date) {
      return date.toLocaleDateString('fr-CA', { month: 'long', day: 'numeric' })
    },
    getTime (date) {
      return date.toLocaleTimeString('fr-CA', { hour: '2-digit', minute: '2-digit', hour12: false })
    },
    deleteEvent () {
      this.$store.commit('Dialogs/setElemToDelete', this.selectedEvent)
      this.$store.commit('Dialogs/setDeleteEvent', true)
    }
  }
}
</script>

<template lang='pug'>
div.devoir-preview()
  template(v-if='loading')
    v-list-item(depressed, flat)
      v-skeleton-loader.w-100(type="list-item-avatar-three-line")
  template(v-else-if='selectedEvent')
    v-list-item(@click='')
      .color-banner(:class='selectedEvent.color')
      v-list-item-content

        v-list-item-title
          span.f5 {{selectedEvent.name}}
        v-list-item-subtitle
          span.b.ttu {{getDate(selectedEvent.start)}}
          span.b.ttu.ml1(v-if='getDate(selectedEvent.start) !== getDate(selectedEvent.end)') - {{getDate(selectedEvent.end)}}
        v-list-item-subtitle(v-if='selectedEvent.timed')
          span {{getTime(selectedEvent.start)}}
          font-awesome-icon.mh2(:icon="['far', 'long-arrow-right']")
          span {{getTime(selectedEvent.end)}}
      v-list-item-action
        v-menu(offset-y)
          template(v-slot:activator='{ on }')
            v-btn.mr1(icon,small, @click.prevent.stop='', v-on='on')
              font-awesome-icon.f6(:icon="['fas', 'ellipsis-v']")
          v-card.pv2
            v-list(dense, nav)
              v-list-item(@click='', disabled)
                font-awesome-icon.f6.mr2(:icon="['fad', 'share-square']")
                v-list-item-content
                  v-list-item-title Partager l'événement
              v-list-item(@click='showCreateEvent = !showCreateEvent')
                font-awesome-icon.f6.mr2(:icon="['fad', 'pen']", disabled)
                v-list-item-content
                  v-list-item-title Modifier l'événement
              v-list-item(@click='deleteEvent')
                font-awesome-icon.f6.mr2(:icon="['fad', 'trash-alt']")
                v-list-item-content
                  v-list-item-title Supprimer l'événement
              v-list-group(@click.stop='', color='secondary', disabled)
                template(v-slot:activator='')
                  v-list-item-content
                    v-list-item-title Déplacer vers...
                template(v-for='(lis, index) in 8')
                  v-list-item(color='red', :key='index', @click='')
                    v-list-item-content
                      v-list-item-title Le titre du calendrier {{index + 1}}
</template>
<style lang='sass' scoped>

</style>
