<script>

export default {
  name: 'CalendrierPreview',
  props: {
    hardcodded: { required: false, type: Boolean, default: false },
    index: { required: true, type: Number }
  },
  computed: {
  },
  data () {
    return {
      loading: false,
      activeCalendar: false
    }
  },
  methods: {
    toggleCalendar () {
      this.activeCalendar = !this.activeCalendar
    }
  }
}
</script>

<template lang='pug'>
v-expansion-panel.calendar-preview(:key='index')
  v-expansion-panel-header
    v-list-item
      v-list-item-avatar(color='red', size='20')
      v-list-item-content
        //- v-list-item-subtitle Matières de l'exercice
        v-list-item-title Le titre du calendrier
        v-list-item-subtitle Type de calendrier ici
      v-list-item-action
        v-btn(:small='!$store.state.App.mobileTemplate', :x-small='$store.state.App.mobileTemplate', rounded, :color='activeCalendar ? "error" : "success"', depressed, dark, :outlined='!activeCalendar', @click.stop='toggleCalendar()') {{ activeCalendar ? 'Retirer' : 'Ajouter'}}
  v-expansion-panel-content
    | Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
</template>
<style lang='sass' scoped>
.calendar-preview
  .v-expansion-panel-header
    padding: 5px 10px 5px 0
</style>
