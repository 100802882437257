<script>
import CreateEvent from '@/components/dialogs/calendrier/createEvent.vue'
import DeleteEvent from '@/components/dialogs/calendrier/deleteEvent.vue'
import CreateCalendar from '@/components/dialogs/calendrier/createCalendar.vue'
import EventCard from '@/components/calendar/eventCard.vue'
import EventPreview from '@/components/calendar/eventPreview.vue'

export default {
  name: 'calendar',
  components: {
    CreateEvent,
    DeleteEvent,
    CreateCalendar,
    EventCard,
    EventPreview
  },
  data () {
    let dateRef = new Date()
    dateRef = dateRef.getUTCFullYear() + '-' + (dateRef.getUTCMonth() + 1) + '-' + dateRef.getUTCDate()
    return {
      today: dateRef,
      fab: false,
      focus: '',
      type: 'week',
      typeToLabel: {
        month: 'Mois',
        week: 'Semaine',
        day: 'Jour'
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['blue', 'indigo', 'cyan', 'green', 'orange', 'grey darken-1', 'pink'],
      names: ['Réunion', 'Congé', 'Remise', 'Événement', 'Fête', 'Conférence', 'Spécial'],
      activeCalendars: [],
      calendars: [],
      // Les variables pour le drag
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      editMode: false,
      deleteMode: false,
      pageEvents: 1,
      pageDevoirs: 1,
      start: null,
      end: null,
      devoirs: null,
      intervalVar: null,
      enfantsCalendars: []
    }
  },
  mounted () {
    this.updateTime()
    this.$refs.calendar.checkChange()
    this.getEnfants()
  },
  watch: {
    '$store.state.User.relations' (newVal) {
      if (newVal) {
        this.getEnfants()
      }
    },
    '$store.state.App.mobileTemplate' (newVal) {
      if (newVal) {
        this.editMode = false
        this.deleteMode = false
      }
    },
    activeCalendars: {
      // immediate: true,
      deep: true,
      handler (newVal, oldVal) {
        if (newVal.length !== oldVal.length) {
          this.updateValueFromCalendar(true)
        }
      }
    }
  },
  computed: {
    calendarsAvailable () {
      return this.$store.state.User.groupes.map((e) => { return { calendrier: e.calendrier, salle: { id: e.id, titre: e.titre } } })
    },
    showElevesSelection: {
      set (val) { this.$store.commit('Dialogs/setElevesSelection', val) },
      get () { return this.$store.state.Dialogs.showElevesSelection }
    },
    showCreateEvent: {
      set (val) { this.$store.commit('Dialogs/setCreateEvent', val) },
      get () { return this.$store.state.Dialogs.showCreateEvent }
    },
    showCreateCalendar: {
      set (val) { this.$store.commit('Dialogs/setCreateCalendar', val) },
      get () { return this.$store.state.Dialogs.showCreateCalendar }
    },
    showDeleteEvent: {
      set (val) { this.$store.commit('Dialogs/setDeleteEvent', val) },
      get () { return this.$store.state.Dialogs.showDeleteEvent }
    },
    filteredEvents () {
      let activeEvents = this.events.filter(ev => {
        return this.activeCalendars.find(elem => elem === ev.calendar)
      })
      return activeEvents
    },
    nowY () {
      // FIX PARCE QU'ON UTILISE LA PROP NOW DANS LE CALENDAR
      var hours = new Date().getHours()
      var minutes = new Date().getMinutes()
      return this.$refs.calendar ? this.$refs.calendar.timeToY(hours + ':' + minutes) + 'px' : '-10px'
      // return this.$refs.calendar ? this.$refs.calendar.timeToY(this.$refs.calendar.times.now) + 'px' : '-10px'
    },
    calendarQuickActions () {
      if (this.editMode || this.deleteMode) {
        return true
      } else {
        return this.fab
      }
    }
  },
  apollo: {
    devoirs: {
      query: require('@/graphql/queries/v2/calendrierCalendrierElements.gql'),
      variables () {
        let calendriers = []
        if (this.activeCalendars.length === 0) {
          calendriers = this.calendarsAvailable.map(e => e.calendrier.id)
        } else {
          calendriers = this.activeCalendars
        }
        this.pageDevoirs = 1
        return {
          filtre: {
            debut: this.start.toJSON(),
            fin: this.end.toJSON(),
            calendriers
          },
          page: 1
        }
      },
      update (data) {
        if (this.pageDevoirs === 1) {
          this.$nextTick(() => {
            this.fetchMoreDevoirs()
            this.addEvents(data.calendrierCalendrierElements.resultat, 'devoir')
          })
        }
        return data.calendrierCalendrierElements
      },
      fetchPolicy: 'network-only',
      skip () {
        return !this.start || !this.end || !(this.start instanceof Date) || !(this.end instanceof Date)
      }
    }
  },
  beforeDestroy () {
    if (this.intervalVar) {
      window.clearInterval(this.intervalVar)
    }
  },
  methods: {
    getEnfants () {
      if (this.$store.state.User.relations) {
        this.$store.state.User.relations.forEach(enf => {
          if (enf.inferieur && enf.inferieur.id) {
            this.getEnfantsCalendriers(enf.inferieur)
          }
        })
      }
      console.log('on set les enfants')
    },
    async getEnfantsCalendriers (enfant) {
      console.log('enfant', enfant.id)
      this.$apollo.query({
        query: require('@/graphql/queries/v2/searchCalendriers.gql'),
        variables: {
          limit: 100,
          filtre: {
            usagers: enfant.id
          }
        },
        fetchPolicy: 'network-only'
      }).then((data) => {
        if (data) {
          console.log('on set la liste des calendriers enfants')
          this.enfantsCalendars.push({ enfant: enfant, calendriers: data.data.searchCalendriers.resultat })
        }
      })
    },
    addEvents (events, type) {
      // ON IRA CHOPÉ LA COULEUR DANS LA META
      events.forEach((el) => {
        // const dateDeb = new Date(el.debutAt)
        const dateFin = el.finAt ? new Date(el.finAt) : null
        const ind = this.events.findIndex(e => e.batch === el.batch)
        if (ind >= 0) {
          this.events[ind].data.push(el)
        } else {
          this.events.push({
            name: el.titre,
            // start: dateDeb.getUTCFullYear() + '-' + (dateDeb.getUTCMonth() + 1) + '-' + dateDeb.getUTCDate(),
            start: dateFin ? dateFin.getUTCFullYear() + '-' + (dateFin.getUTCMonth() + 1) + '-' + dateFin.getUTCDate() : null,
            end: dateFin ? dateFin.getUTCFullYear() + '-' + (dateFin.getUTCMonth() + 1) + '-' + dateFin.getUTCDate() : null,
            color: type === 'devoir' ? 'green' : 'blue',
            timed: false,
            batch: el.batch,
            calendrier: el.calendrier,
            type,
            data: [el]
          })
        }
      })
    },
    async fetchMoreDevoirs () {
      if (this.devoirs.total > this.devoirs.resultat.length) {
        this.pageDevoirs++
        await this.$apollo.queries.devoirs.fetchMore({
          variables: {
            page: this.pageDevoirs
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newRes = fetchMoreResult.calendrierCalendrierElements.resultat
            this.addEvents(fetchMoreResult.calendrierCalendrierElements.resultat, 'devoir')
            return {
              calendrierCalendrierElements: {
                __typename: previousResult.calendrierCalendrierElements.__typename,
                resultat: [...previousResult.calendrierCalendrierElements.resultat, ...newRes],
                total: previousResult.calendrierCalendrierElements.total
              }
            }
          }
        })
        this.fetchMoreDevoirs()
      }
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      const index = this.calendarsAvailable.findIndex(c => c.calendrier.id === event.calendrier.id)
      return this.colors[index]
    },
    setToday () {
      this.focus = ''
      this.updateValueFromCalendar()
    },
    prev () {
      this.$refs.calendar.prev()
      this.updateValueFromCalendar()
    },
    next () {
      this.$refs.calendar.next()
      this.updateValueFromCalendar()
    },
    showEvent ({ nativeEvent, event }) {
      if (this.deleteMode) {
        this.showDeleteEvent = true
        this.$store.commit('Dialogs/setElemToDelete', event)
      } else {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => {
            this.selectedOpen = true
          }, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }
        nativeEvent.stopPropagation()
      }
    },
    updateRange ({ start, end }) {
      this.updateTime()
      this.updateValueFromCalendar()
    },
    updateValueFromCalendar (force) {
      let update = false
      if (this.$refs.calendar) {
        if (!this.start || (this.start.getTime() !== new Date(this.$refs.calendar.lastStart.date + 'T00:00:00').getTime())) {
          this.start = new Date(this.$refs.calendar.lastStart.date + 'T00:00:00')
          update = true
        }
        if (!this.end || (this.end.getTime() !== new Date(this.$refs.calendar.lastEnd.date + 'T00:00:00').getTime())) {
          this.end = new Date(this.$refs.calendar.lastEnd.date + 'T00:00:00')
          update = true
        }
        if (update || force) {
          this.pageEvents = 1
          this.pageDevoirs = 1
          this.events = []
        }
      }
    },
    getDate (date) {
      return date.toLocaleDateString('fr-CA', { month: 'long', day: 'numeric' })
    },
    getTime (date) {
      return date.toLocaleTimeString('fr-CA', { hour: '2-digit', minute: '2-digit', hour12: false })
    },
    // Pour updater la ligne de temps sur jours/semaines
    getCurrentTime () {
      var hours = new Date().getHours()
      var minutes = new Date().getMinutes()
      return this.$refs.calendar ? hours * 60 + minutes : 0
      // return this.$refs.calendar ? this.$refs.calendar.times.now.hour * 60 + this.$refs.calendar.times.now.minute : 0
    },
    updateTime () {
      if (this.intervalVar) {
        window.clearInterval(this.intervalVar)
      }
      this.intervalVar = window.setInterval(() => this.$refs.calendar.updateTimes(), 60 * 1000)
    },
    // Pour drag events
    startDrag ({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },
    startTime (tms) {
      const mouse = this.toTime(tms)
      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start

        this.dragTime = mouse - start
      } else {
        if (this.editMode) {
          this.createStart = this.roundTime(mouse)
          this.createEvent = {
            name: `Event #${this.events.length}`,
            color: this.rndElement(this.colors),
            start: this.createStart,
            end: this.createStart,
            timed: true,
            calendar: 'partage-' + this.rnd(0, 2)
          }
          this.events.push(this.createEvent)
        }
      }
    },
    extendBottom (event) {
      this.createEvent = event
      this.createStart = event.start
      this.extendOriginal = event.end
    },
    mouseMove (tms) {
      const mouse = this.toTime(tms)
      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start
        const end = this.dragEvent.end
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = new Date(newStart)
        this.dragEvent.end = new Date(newEnd)
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)

        this.createEvent.start = new Date(min)
        this.createEvent.end = new Date(max)
      }
    },
    endDrag () {
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    cancelDrag () {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }
      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    roundTime (time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - time % roundDownTime
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime (tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    toggleMode (mode) {
      if (mode === 'edit') {
        this.deleteMode = false
        this.editMode = !this.editMode
      } else if (mode === 'delete') {
        this.editMode = false
        this.deleteMode = !this.deleteMode
      }
    },
    print () {
      window.print()
    },
    removeEvent () {
      this.events = []
      this.$apollo.queries.devoirs.refetch()
      // pas la meilleure façon on pourrait juste enleve l'élément du store mais comme tableau je sais pas si à jour
      this.$store.commit('Dialogs/setElemToDelete', null)
    },
    refetchQuery () {
      this.$apollo.queries.devoirs.refetch()
    }
  }
}
</script>

<template lang='pug'>
  .calendar-index
    create-event(@refetch='refetchQuery')
    delete-event(@removeEvent='removeEvent')
    create-calendar
    //- pre {{enfantsCalendars}}
    .new-event-button(v-if='!$store.state.App.mobileTemplate')
      v-btn(large, fab, dark, color='primary', @click='print()', v-tooltip.left="{ content: 'Imprimer' , offset:'15px'}")
        font-awesome-icon.f4(:icon="['fad', 'print']")
      v-btn.mt2(large, fab, dark, color='success', @click='showCreateEvent = true', v-tooltip.left="{ content: 'Ajouter un événement' , offset:'15px'}")
        font-awesome-icon.f4(:icon="['far', 'plus']")
      v-btn.mt2(:outlined='editMode', large, fab, dark, color='success', @click='toggleMode("edit")', disabled, v-tooltip.left="{ content: 'Ajouter sur le calendrier' , offset:'15px'}")
        font-awesome-icon.f4(:icon="[editMode ? 'far' : 'fad', editMode ? 'times' : 'pen']")
      v-btn.mt2(:outlined='deleteMode', large, fab, dark, color='error', @click='toggleMode("delete")', v-tooltip.left="{ content: 'Supprimer des événements' , offset:'15px'}")
        font-awesome-icon.f4(:icon="[deleteMode ? 'far' : 'fad',  deleteMode ? 'times' : 'trash-alt']")
    .calendar-wrapper
      v-toolbar.filters-toolbar(color='dark', dark, v-if='$store.state.App.mobileTemplate')
        div.flex.items-center.w-100
          v-select(
            v-model='activeCalendars'
            :items='calendars'
            item-text='titre'
            return-object
            outlined, rounded, dense, solo, light
            clearable
            placeholder='Tous les calendriers'
            hide-details
            :menu-props='{"closeOnContentClick": true}'
          )
            template(v-slot:prepend-item='')
              v-list-item(@click='filterList = null')
                v-list-item-content
                  v-list-item-title Tous les calendriers
          v-spacer
          v-menu(offset-y)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, v-bind='attrs', v-on='on')
                font-awesome-icon.f4(:icon="['far', 'plus']")
            v-card
              v-list(dense)
                v-list-item(@click='showCreateEvent = true')
                  v-list-item-title Ajouter un événement
                v-list-item(@click='showCreateCalendar = true')
                  v-list-item-title Gérer les calendriers

      perfect-scrollbar.calendar-navigation(:options='{wheelPropagation: false}')
        v-date-picker.preview-date-picker(v-model='focus', color='primary', light)
        div
          .navigation__header.pa3.primary
            span.white--text Calendriers
          .navigation__content
            v-list.light-list(dense, expand)
              v-list-group.secondary--text(value='true')
                template(v-slot:activator='')
                  v-list-item-content
                    v-list-item-title Mes Calendriers
                //- pre {{calendarsAvailable}}
                template(v-for='(n, index) in calendarsAvailable')
                  v-list-item.list-group-item.item(:key='index', text, color='secondary')
                    v-list-item-action(style='margin-right:5px;')
                      //- pre {{n.calendrier.id}}
                      v-checkbox(v-model='activeCalendars', :value='n.calendrier.id', dense, :color='colors[index]')
                    v-list-item-content
                      v-list-item-title(style='white-space: normal;') {{n.salle.titre}}
                      //- v-list-item-subtitle {{n.calendrier.titre}}
                    v-list-item-action
                      v-menu(offset-y)
                        template(v-slot:activator='{ on }')
                          v-btn(icon,x-small, @click.prevent.stop='', v-on='on')
                            font-awesome-icon.f6(:icon="['fas', 'ellipsis-v']")
                        v-card
                          v-list(dense)
                            v-list-item(@click='showElevesSelection = !showElevesSelection', disabled)
                              v-list-item-title Partager le calendrier
                            v-list-item(@click='', disabled)
                              v-list-item-title Modifier les informations
                            v-list-item(@click='', disabled)
                              v-list-item-title Supprimer
                  v-divider(v-if='index < calendarsAvailable.length - 1')
              v-divider(color='dark')
              template(v-for='(enfant, index) in enfantsCalendars')
                v-list-group.secondary--text(value='false')
                  template(v-slot:activator='')
                    v-list-item-content
                      v-list-item-title.ttc {{enfant.enfant.info.prenom}}
                  template(v-for='(n, index) in enfant.calendriers')
                    v-list-item.list-group-item.item(:key='index', text, color='secondary')
                      v-list-item-action(style='margin-right:5px;')
                        v-checkbox(v-model='activeCalendars', :value='n.id', dense, :color='colors[index]')
                      v-list-item-content
                        v-list-item-title(style='white-space: normal;') {{n.proprietaire.titre}}
                    v-divider(v-if='index < enfant.calendriers.length - 1')
                v-divider(v-if='index < enfantsCalendars.length - 1', color='dark')

          div.list-creation__button__wrapper
            v-btn.list-creation__button.mt4(rounded, dark, color='secondary', @click='showCreateCalendar = true') Ajouter un calendrier

      .calendar-content__wrapper()
        v-sheet.calendar-toolbar(height='64')
          v-toolbar(flat, color='secondary', dark)
            div.flex.items-center.w-100
              v-btn(rounded, color='primary', @click='setToday', :small='$store.state.App.mobileTemplate', :icon='$store.state.App.mobileTemplate')
                span(v-if='!$store.state.App.mobileTemplate') Aujourd'hui
                font-awesome-icon.f5(v-else, :icon="['fad', 'calendar-day']")
              v-spacer
              v-btn(fab, text, @click='prev', :small='$store.state.App.mobileTemplate')
                font-awesome-icon.f5(:icon="['far', 'arrow-left']")
              v-toolbar-title.tc.ttc(v-if='$refs.calendar', style='min-width:150px') {{ $refs.calendar.title }}
              v-btn(fab, text, @click='next', :small='$store.state.App.mobileTemplate')
                font-awesome-icon.f5(:icon="['far', 'arrow-right']")
              v-spacer
              v-menu(bottom, right)
                template(v-slot:activator='{ on, attrs }')
                  v-btn(color='primary', v-bind='attrs', v-on='on', :small='$store.state.App.mobileTemplate', rounded)
                    span(v-if='!$store.state.App.mobileTemplate') {{ typeToLabel[type] }}
                    font-awesome-icon.f5(v-else, :icon="['fad', 'calendar-alt']")
                    v-icon(right) mdi-menu-down
                v-list
                  v-list-item(@click="type = 'day'")
                    v-list-item-title Jour
                  v-list-item(@click="type = 'week'")
                    v-list-item-title Semaine
                  v-list-item(@click="type = 'month'")
                    v-list-item-title Mois
        v-sheet.calendar-content(:class='{"edit-active" : editMode, "delete-active" : deleteMode}')
          v-calendar(
            ref='calendar'
            v-model='focus'
            color='primary'
            :events='events'
            :event-color='getEventColor'
            :type='type'
            locale='fr-CA'
            @click:event='showEvent'
            @click:more='viewDay'
            @click:date='viewDay'
            @change='updateRange'
            @mousedown:event="startDrag"
            @mousedown:time="startTime"
            @mousemove:time="mouseMove"
            @mouseup:time="endDrag"
            @mouseleave.native="cancelDrag"
          )
            template(v-slot:day-body='{ date, week }')
              .v-current-time(:class='{ first: date === week[0].date }', :style='{ top: nowY }')
            template(v-slot:event='{ event, timed, eventSummary }')
              .v-event-draggable(v-html='eventSummary()')
              div.delete-icon(v-if='deleteMode', @click.prevent.stop='showDeleteEvent = !showDeleteEvent')
                font-awesome-icon.f6(:icon="['fad', 'trash-alt']")
              .v-event-drag-bottom(v-if='timed', @mousedown.stop='extendBottom(event)')
          template(v-if='$store.state.App.mobileTemplate')
            v-bottom-sheet(v-model='selectedOpen', inset)
              v-sheet.text-center.transparent(min-height='300')
                event-card(:selectedEvent='selectedEvent', @close='selectedOpen = false')
          v-menu(v-else, v-model='selectedOpen', :close-on-content-click='false', :activator='selectedElement', offset-x, max-width='350px')
            event-card(:selectedEvent='selectedEvent', @close='selectedOpen = false')
        div.mobile-list(v-if='$store.state.App.mobileTemplate && $refs.calendar')
          v-toolbar(color='secondary', dark, flat, dense)
            v-toolbar-title Événements pour {{ $refs.calendar.title }}
          v-list(dense)
            template(v-for='(event, ind) in events')
              event-preview(:selectedEvent='event')
              v-divider(v-if='ind < events.length - 1')
            div.pa2
              v-btn(block, rounded, color='primary', depressed, @click='showCreateEvent = showCreateEvent') Créer un événement
</template>
<style lang='sass'>
  @import 'src/styles/pages/_calendar'

  .calendar-wrapper
    .filters-toolbar
      .v-toolbar__content
        padding: 10px
        .v-input
          .v-input__slot
            padding: 0 10px
            min-height: 36px
            input
              padding: 4px 0
  .preview-date-picker
    margin-bottom: 20px
    .v-picker__title
      display: none
    .v-picker__body
      width: 250px !important
      .v-date-picker-header
        // padding:
      .v-date-picker-table
        height: 100%
        table
          thead
            tr
              th
                padding: 3px 0
                width: 30px
          tbody
            tr
              td
                width: 30px
                .v-btn
                  height: 24px
                  width: 24px
  .new-event-button
    position: fixed
    bottom: 40px
    right: 20px
    z-index: 10
    display: flex
    flex-direction: column
    background-color: var(--v-secondary-base)
    padding: 10px
    border-radius: $border-radius-root
    @media all and (max-width: $medium)
      bottom: 70px
  .v-current-time
    height: 2px
    background-color: #ea4335
    position: absolute
    left: -1px
    right: 0
    pointer-events: none
    &.first::before
      content: ''
      position: absolute
      background-color: #ea4335
      width: 12px
      height: 12px
      border-radius: 50%
      margin-top: -5px
      margin-left: -6.5px
  .v-event-draggable
    padding-left: 6px
  .v-event-timed
    user-select: none
    -webkit-user-select: none
  .v-event-timed
    .delete-icon
      position: absolute
      top: 0
      right: 0
      padding: 3px
      border-radius: 0 0 0 10px
      background-color: var(--v-error-base)
    &:hover
      .v-event-drag-bottom
        opacity: 0.5
    .v-event-drag-bottom
      position: absolute
      left: 0
      right: 0
      bottom: 0px
      height: 8px
      padding: 4px 0
      cursor: ns-resize
      background-color: var(--v-secondary-base)
      opacity: 0
      &::after
        position: absolute
        left: 50%
        height: 8px
        border-top: 1px solid white
        border-bottom: 1px solid white
        width: 16px
        margin-left: -8px
        opacity: 0.8
        content: ''
      &:hover
        opacity: 1

  // Options pour print (Fait rapide, à cleaner plus tard)
  @media print
    .v-main
      padding: 0 !important
    .navigation-sidebar
      display: none !important
    .background-texture
      display: none !important
    .secondary-navbar
      display: none !important
    .v-footer
      display: none !important
    .new-event-button
      display: none !important
    .calendar-navigation
      display: none !important
    .v-event-timed
      border-color: black !important
</style>
